.sidebar-root {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-transition: margin-left 250ms ease-out,
    -webkit-transform 250ms ease-out;
  transition: margin-left 250ms ease-out, -webkit-transform 250ms ease-out;
  transition: margin-left 250ms ease-out, transform 250ms ease-out;
  transition: margin-left 250ms ease-out, transform 250ms ease-out,
    -webkit-transform 250ms ease-out;
  width: 244px;
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.sidebar-root {
  height: 100vh;
  overflow: hidden;
  /* background-color: #2e0546; */
  background-color: #1d0829;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.sidebar-root__header a {
  text-decoration: none;
  margin-left: 1rem;
  color: #fff;
}

.sidebar-root__header {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 20px 0;
  color: #fff;
}
.sidebar-root button {
  background: transparent;
  border: 0;
  padding: 0;
  text-transform: none;
}
.SidebarHeader-sideBurgerIcon {
  cursor: pointer;
  fill: #9ca6af;
  -webkit-box-flex: 0;
  flex-grow: 0;
  margin-right: 15px;
  padding: 0 10px;
}
.SideBurgerIcon {
  height: 16px;
}
svg:not(:root) {
  overflow: hidden;
}
.SideBurgerIcon-image {
  height: 16px;
  width: 25px;
}
.SidebarHeader-sideBurgerIcon:hover,
.SidebarHeader-sideBurgerIcon:focus {
  fill: #fff;
  outline: none;
}

.sidebar-root__top-links a {
  color: #cbd4db;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-root__section {
  border-bottom: 1px solid #594067;
  padding-bottom: 10px;
}

.sidebar-root__projects {
  display: flex;
  justify-content: space-between;
}
.sidebar-root__add {
  color: rgba(255, 255, 255, 0.5);
  fill: rgba(255, 255, 255, 0.5);
  outline: none;
  visibility: visible;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  padding: 5px;
  margin-right: 10px;
}

.sidebar-root__add:hover {
  color: rgba(255, 255, 255, 1);
  fill: rgba(255, 255, 255, 1);
}

.sidebar-root__add svg {
  height: 12px;
  width: 12px;
}

.sidebar-root__section {
  margin-bottom: 10px;
}

.sidebar-root__project {
  display: flex;
  justify-content: space-between;
}
