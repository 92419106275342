.project-task__row {
  /* background-color: #edf8ff; */
  /* padding-right: 20px; */
  padding-right: 0px;
  /* padding-right: 16px; */
  -webkit-transition: box-shadow 100ms ease-in-out;
  transition: box-shadow 100ms ease-in-out;
  contain: style;
  display: -webkit-box;
  display: flex;
  margin-bottom: -1px;
  margin-top: -1px;
  height: 36px;
}
.project-task__row:hover,
.project-task__row:hover .project-task__cell {
  background-color: #f6f8f9;
}
.project-task__cell {
  background-color: #fff;
  border: 1px solid #e8ecee;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 10px;
  z-index: 0;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  height: 36px;
  overflow: hidden;
}
.project-task__cell:hover {
  background-color: #f6f8f9;
}

.project-task__cell-taskname label {
  border: 0;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  min-width: 20px;
  padding: 0 5px;
  overflow: hidden;
  margin-right: 5px;
}
.project-task__cell-taskname {
  /* padding: 0 5px 0 20px; */
  padding: 0 5px 0 0px;
  border-left: 0;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-width: 1px;
}

.project-task__drag-handle {
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  width: 20px;
  z-index: 301;
  fill: rgba(111, 119, 130, 0);
  transition: fill 100ms ease-in-out;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  display: flex;
  cursor: grab;
  -webkit-box-align: center;
  align-items: center;
}
.project-task__handle-icon {
  width: 12px;
  height: 12px;
}
.project-task__drag-handle:hover {
  fill: #6f7782;
}
.project-task__cell:hover .project-task__drag-handle {
  fill: #6f7782;
}

.project-task__details-area:hover .project-task__details-btn {
  opacity: 1;
}
.project-task__details-area {
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  height: 100%;
}

.project-task__details-btn {
  -webkit-box-align: center;
  align-items: center;
  color: #6f7782;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  fill: currentColor;
  font-size: 12px;
  height: 100%;
  -webkit-box-pack: end;
  justify-content: flex-end;
  margin-left: auto;
  opacity: 0;
}

.project-task__details-btn-icon {
  margin-left: 2px;
  margin-top: 1px;
}

.project-task__cell:last-child {
  border-right: 0;
}
.project-task__cell {
  font-size: 13px;
}

.project-task__cell:hover {
  border-color: #cbd4db;
  background-color: #f6f8f9;
  z-index: 1;
}

.project-task_cell-centered {
  justify-content: center;
}
