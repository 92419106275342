@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #151b26;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.view-page {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  position: relative;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

main {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1;
  min-height: 1px;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.Button {
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 13px;
  -webkit-box-pack: center;
  justify-content: center;
  line-height: 1;
  min-width: 50px;
  text-align: center;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: background, border, box-shadow, color, fill;
  transition-property: background, border, box-shadow, color, fill;
}
.Button {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  padding: 0 9px;
}
.Button--secondary:not(.Button--disabled) {
  background: #fff;
  border-color: #9ca6af;
  color: #6f7782;
  fill: #6f7782;
}
.Button--secondary:not(.Button--disabled):hover {
  background-color: #f6f8f9;
  border-color: #6f7782;
  border-style: solid;
  color: #273240;
  fill: #273240;
}
.MenuAnchor {
  color: #6f7782;
  fill: #6f7782;
  -webkit-box-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-box;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  height: 24px;
  padding: 0 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.MenuAnchor--default:hover,
.MenuAnchor--default:focus {
  background-color: #e8ecee;
}

.MenuAnchor:hover,
.MenuAnchor:focus {
  color: #273240;
  fill: #273240;
  text-decoration: none;
}
a {
  color: #5c068c;
  cursor: pointer;
  text-decoration: none;
}
.drawer-scrollbar > div {
  overflow-x: hidden !important;
  padding-bottom: 15px;
}
.d-none {
  display: none !important;
}

.rbc-event {
  background-color: #9c27b0 !important;
}
.rbc-today {
  background-color: #e1bee7 !important;
}
