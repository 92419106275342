.top-header__bar {
  -webkit-box-align: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  padding: 0 20px;
  position: relative;
  z-index: 200;
  padding-top: 10px;
  justify-content: space-between;
}
.top-header__bar-border-top {
  border-top: 1px solid #e8ecee;
  padding-bottom: 10px;
}
.top-header__bar-without-shadow {
  box-shadow: none;
}
.top-header__user {
  color: #383838;
  font-weight: 600;
}

.top-header__left-actions {
  margin-top: 10px;
  flex: 1;
  min-width: 1px;
  position: relative;
  white-space: nowrap;
}
.top-header__list {
  display: flex;
}
.top-header__tab {
  color: #6f7782;
  cursor: default;
  display: -webkit-box;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  min-width: 1px;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: box-shadow, color;
  transition-property: box-shadow, color;
  word-wrap: break-word;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
}
.top-header__tab.active {
  color: #000;
}
.top-header__tab:not(:last-child) {
  margin-right: 20px;
}

.top-header__left-project-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  -webkit-box-align: center;
  align-items: center;
  color: #151b26;
  -webkit-box-flex: 0;
  flex: 0 1 auto;
  min-width: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.top-header__right-container {
  display: flex;
  flex-direction: row;
}
.top-header__user {
  margin-left: 2rem;
}
.top-header__members {
  padding-right: 2rem;
  border-right: 1px solid #d5d5d6;
  display: flex;
}
.top-header__members-count {
  font-weight: 600;
  color: #4a4a4a;
  margin-right: 10px;
}
.top-header__members-invite {
  background: #fff;
  border-color: #9ca6af;
  color: #6f7782;
  fill: #6f7782;
  margin-left: 0.7rem;
}

.Tab-Link {
  color: #6f7782;
  text-decoration: none;
}

.Tab-selectableTab {
  display: block;
  padding-bottom: 8px;
}

.Tab-selectableTab:hover,
.Tab-selectableTab--isActive {
  box-shadow: inset 0 -2px #cbd4db;
  color: #151b26;
}

.Tab-selectableTab--isSelected,
.Tab-selectableTab--isSelected .Tab-selectableTab {
  box-shadow: inset 0 -2px #8014b9 !important ;
  color: #5c068c !important;
}

.Tab-selectableTab--isSelected,
.Tab-selectableTab--isDisabled:hover {
  cursor: not-allowed;
  pointer-events: all !important;
}

.MenuAnchor.active {
  /* color: #008ce3 !important; */
  color: #5c068c !important;
  fill: none;
  background-color: inherit;
  /* text-decoration: underline; */
  font-weight: 700;
}
