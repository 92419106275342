.project-tasks__container {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-height: 1px;
  position: relative;
  width: 100%;
}
.project-tasks__header-shadow {
  border-bottom: 0;
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.15);
  z-index: 400;
}
.project-tasks__header {
  background-color: #fff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  height: 36px;
  position: relative;
}

.project-tasks__header-left {
  -webkit-box-align: stretch;
  align-items: stretch;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  height: 36px;
  left: 0;
  /* position: absolute; */
  z-index: 100;
}
.project-tasks__header-cell {
  -webkit-box-align: stretch;
  align-items: stretch;
  color: #6f7782;
  cursor: pointer;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  font-size: 12px;
  margin-right: -1px;
  padding-left: 20px;
  -webkit-box-direction: normal;
}
.project-tasks__header-cell:hover {
  background-color: #f6f8f9;
  color: #151b26;
  fill: #151b26;
}
.project-tasks__header-border {
  -webkit-box-align: center;
  align-items: center;
  /* border-right: 1px solid #e8ecee; */
  border-top: 1px solid #e8ecee;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 0 auto;
  -webkit-box-pack: justify;
  justify-content: space-between;
}
.project-tasks__header-task-name {
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-direction: normal;
  font-size: 12px;
  color: #6f7782;
  cursor: pointer;
}

.project-tasks__header-right {
  height: 36px;
  /* position: absolute; */
  right: 0;
}
.project-tasks__header-right-wrapper {
  height: 100%;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  position: relative;
  margin-left: auto;
}
.project-tasks__header-right-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.project-tasks__header-field-heading {
  -webkit-box-align: center;
  align-items: center;
  border: 1px solid #e8ecee;
  border-bottom: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #6f7782;
  cursor: pointer;
  display: -webkit-inline-box;
  display: inline-flex;
  fill: #6f7782;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  font-size: 12px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-right: -1px;
  padding: 0 10px;
  height: 100%;
}

.project-tasks__header-field-heading:hover {
  background-color: #f6f8f9;
  color: #151b26;
  fill: #151b26;
}

.project-tasks__section-header-container {
  height: 50px;
  width: 100%;
}

.project-tasks__section-header {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  -webkit-box-align: center;
  align-items: center;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 30px;
  padding-right: 30px;
  position: relative;
  height: 100%;
}

.project-tasks__section-btn {
  font-size: 17px;
  font-weight: 600;
  line-height: 21px;
  -webkit-box-flex: 0;
  flex-grow: 0;
  margin-left: 10px;
  margin-right: auto;
  min-width: 1px;
  outline: none;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.project-tasks__container-vertical-scroll {
  -webkit-transform: translate3d(0, 0, 0);
  contain: strict;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  overflow-x: hidden;
  padding-bottom: 1px;
  position: relative;
  min-height: 1px;
  overflow-y: auto;
}
