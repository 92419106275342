.sidebar-root__link {
  color: unset;
  -webkit-box-align: center;
  align-items: center;
  display: -webkit-box;
  display: flex;
  fill: #9ca6af;
  height: 28px;
  line-height: 28px;
  padding: 0 10px 0 15px;
  color: #cbd4db;
}

.sidebar-root__link:hover,
.sidebar-root__link:focus {
  background-color: rgba(255, 255, 255, 0.08);
}

.sidebar-root__link:active,
.sidebar-root__link.active {
  background-color: rgba(255, 255, 255, 0.05);
}

.sidebar-root__link-sm {
  font-size: 13px;
}

.sidebar-root__link-lg {
  font-size: 16px !important;
}

.sidebar-root__menu-icon {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  margin-right: 5px;
  display: none;
  padding: 0 10px;
}

.sidebar-root__menu-icon:hover,
.sidebar-root__menu-icon:active,
.sidebar-root__menu-icon:focus {
  color: rgba(255, 255, 255);
}

.sidebar-root__project:hover .sidebar-root__menu-icon,
.sidebar-root_menu-icon-visible {
  visibility: visible;
  display: block;
}

.sidebar-root__navlink-offset {
  padding-left: 1.4rem;
}
